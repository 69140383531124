import { useMemo } from 'react';
import { Col, Row, Tabs, Tab, Button, Table } from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector } from 'react-redux';
import { sentenceCase, kebabCase } from 'change-case';

import get from 'lodash.get';
import defaultTo from 'lodash.defaultto';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { pilingReportPageQuery } from '../graphql/report_queries';

const PilingReport = () => {
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pilingReportPageQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const reportPilingMachines = useMemo(() => {
    if (pageData?.pilingMachineList) {
      return [{ id: 0, name: 'UNKNOWN' }, ...get(pageData, 'pilingMachineList', [])];
    }
    return [];
  }, [pageData]);

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <h1 className="h3 mb-3">Piling Reports</h1>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                className="me-2"
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs defaultActiveKey="tableSummaryReport" id="reports" className="mb-3">
            <Tab eventKey="tableSummaryReport" title="Table Summary">
              <Table style={{ width: '50%' }}>
                <tbody>
                  {get(pageData, ['tableSummaryReport', 'report', 'data'], []).map(
                    (row) => {
                      const { status, length, percent } = row;
                      return (
                        <tr key={status}>
                          <td>{sentenceCase(status)}</td>
                          <td className="text-end">{length}</td>
                          <td className="text-end">{percent && `${percent}%`}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="pilingSummaryReport" title="Piling Summary">
              <Table style={{ width: '50%' }}>
                <tbody>
                  {get(pageData, ['pileSummaryReport', 'report', 'data'], []).map(
                    (row) => {
                      const { status, length, percent } = row;
                      return (
                        <tr key={status}>
                          <td>{sentenceCase(status)}</td>
                          <td className="text-end">{length}</td>
                          <td className="text-end">{percent && `${percent}%`}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="pileByDateByPileMachineReport"
              title="Piles by date by pile machine"
            >
              <ReactTable
                rootName="pileByDateByPileMachineReport"
                parentColumns={[
                  {
                    header: 'Finished On',
                    accessorKey: 'finishedOn',
                    enableColumnFilter: false,
                  },
                  ...reportPilingMachines.map(({ id, name }) => ({
                    header: name,
                    accessorKey: id.toString(),
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  })),
                  {
                    header: 'Total Piles',
                    accessorKey: 'totalPiles',
                    enableColumnFilter: false,
                  },
                  {
                    header: 'Total EVA Minutes',
                    accessorKey: 'durationMinutes',
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  },
                  {
                    header: 'Minutes per Pile',
                    accessorKey: 'minutesPerPile',
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  },
                ]}
                data={defaultTo(
                  get(pageData, ['pileByDateByPileMachineReport', 'report', 'data'], []),
                  []
                )}
                doShow={false}
                doDelete={false}
                hideActions
              />
            </Tab>
            <Tab eventKey="tablesMissingPilesReport" title="Tables Missing Piles">
              <ReactTable
                rootName="tablesMissingPiles"
                parentColumns={[
                  {
                    header: 'Id',
                    accessorKey: 'id',
                    enableColumnFilter: false,
                  },
                  {
                    header: 'Table Name',
                    accessorKey: 'name',
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  },
                  {
                    header: 'Table Type',
                    accessorKey: 'tableType',
                    enableColumnFilter: false,
                  },
                  {
                    header: 'Pile Count',
                    accessorKey: 'pileCount',
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  },
                ]}
                data={defaultTo(
                  get(pageData, ['tablesMissingPilesReport', 'report', 'data'], []),
                  []
                )}
                doShow={false}
                doDelete={false}
                hideActions
              />
            </Tab>
            <Tab
              eventKey="tablesIncorrectPilesReport"
              title="Tables Incorrect Piles Count"
            >
              <ReactTable
                rootName="tablesMissingPiles"
                parentColumns={[
                  {
                    header: 'Id',
                    accessorKey: 'id',
                    enableColumnFilter: false,
                  },
                  {
                    header: 'Table Name',
                    accessorKey: 'name',
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  },
                  {
                    header: 'Table Type',
                    accessorKey: 'tableType',
                    enableColumnFilter: false,
                  },
                  {
                    header: 'Pile Count',
                    accessorKey: 'pileCount',
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  },
                ]}
                data={defaultTo(
                  get(pageData, ['tablesIncorrectPilesReport', 'report', 'data'], []),
                  []
                )}
                doShow={false}
                doDelete={false}
                hideActions
              />
            </Tab>
            <Tab eventKey="pilesMissingTableReport" title="Piles Missing Table">
              <ReactTable
                rootName="pilesMissingTable"
                parentColumns={[
                  {
                    header: 'Id',
                    accessorKey: 'id',
                    enableColumnFilter: false,
                  },
                  {
                    header: 'Pile Name',
                    accessorKey: 'name',
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  },
                ]}
                data={defaultTo(
                  get(pageData, ['pilesMissingTableReport', 'report', 'data'], []),
                  []
                )}
                doShow={false}
                doDelete={false}
                hideActions
              />
            </Tab>
            <Tab eventKey="duplicatesReport" title="Duplicates by Name">
              <p>Duplicate Piles</p>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Pile Name</th>
                    <th>Pile Ids</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {defaultTo(
                    get(pageData, ['pileDuplicatesReport', 'report', 'data'], []),
                    []
                  ).map((pile) => {
                    const { name, pileIds, count } = pile;
                    return (
                      <tr key={kebabCase(name)}>
                        <td>{name}</td>
                        <td>{pileIds.join(', ')}</td>
                        <td>{count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <p>Duplicate Tables</p>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Table Name</th>
                    <th>Table Ids</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {defaultTo(
                    get(pageData, ['tableDuplicatesReport', 'report', 'data'], []),
                    []
                  ).map((pile) => {
                    const { name, tableIds, count } = pile;
                    return (
                      <tr key={kebabCase(name)}>
                        <td>{name}</td>
                        <td>{tableIds.join(', ')}</td>
                        <td>{count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PilingReport;
