import { useMemo, useCallback, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import proj4 from 'proj4';
import { DateTime } from 'luxon';
import { Queue } from 'async-await-queue';

import chunk from 'lodash.chunk';
import compact from 'lodash.compact';
import difference from 'lodash.difference';
import get from 'lodash.get';
import intersection from 'lodash.intersection';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import uniq from 'lodash.uniq';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import { toastSuccess, toastError } from '../lib/toast_helpers';
import Confirm from '../components/confirm';
import { pickValues, handleSubmitError } from '../lib/utils';
import { pileImportFormPageQuery } from '../graphql/import_queries';
import {
  pileBatchCreate as pileBatchCreateMutation,
  pileBatchUpdate as pileBatchUpdateMutation,
  pileBatchTableUpdate as pileBatchTableUpdateMutation,
} from '../graphql/pile_queries';
import { pileWhiteList } from '../white_lists';

const pileQueue = new Queue(1, 25);
const priority = -1;

const PileImportForm = () => {
  const projectCode = '000466';
  const navigate = useNavigate();
  const params = useParams();
  const [fileError, setFileError] = useState('');
  const [fileRows, setFileRows] = useState([]);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [pileBatchCreate] = useMutation(pileBatchCreateMutation);
  const [pileBatchUpdate] = useMutation(pileBatchUpdateMutation);
  const [pileBatchTableUpdate] = useMutation(pileBatchTableUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
    refetch: pageRefetch,
  } = useQuery(pileImportFormPageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const enums = useMemo(() => get(pageData, 'enums.enums', {}), [pageData]);

  const pileBatchTableUpdateClicked = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    pileBatchTableUpdate()
      .then(() => {
        toastSuccess(`All piles table link update requested`);
      })
      .catch((err) => {
        const { errorMessage } = handleSubmitError(err);
        toastError(errorMessage);
      });

  const handleFileChange = useCallback(
    async (name, onChange, e) => {
      const { NaskuHammeringStatuses, HammeringStatuses } = enums;
      // const site = get(pageData, 'siteList', []).find(
      //   (s) => s.projectNumber === projectCode
      // );
      // const siteId = site.id;
      const pilingMachines = get(pageData, 'pilingMachineList', []).reduce(
        (accum, { id, naskuMachineRef }) => ({ ...accum, [naskuMachineRef]: id }),
        {}
      );
      const file = get(e, 'target.files.0');
      if (file) {
        setFileError('');
        const fileName = file.name;
        try {
          if (fileName.includes('json')) {
            const fileText = await file.text();
            const fileJson = JSON.parse(fileText);
            const { data: rows } = fileJson;
            if (rows && rows.length > 0) {
              const newFileRows = rows.map((row) => {
                // // id: Int!
                // // tableId: Int
                // // pilingMachineId: Int
                // // name: String!
                // // naskuRef: String
                // // naskuProjectRef: String
                // // hammeringStatus: String
                // // hammeringTime: Int
                // // epsg2105Northing: Float
                // // epsg2105Easting: Float
                // // lat: Float
                // // lng: Float
                // // geom: JSONObject
                // // finishedAt: String
                // // finishedOn: String
                const {
                  id: naskuRef,
                  name: pileName,
                  project,
                  machine,
                  hammeringStatus: naskuHammeringStatus,
                  hammeringTime = 0,
                  northing: epsg2105Northing,
                  easting: epsg2105Easting,
                  finishedAt,
                } = row;
                const { id: naskuProjectRef } = project || {};
                const { id: naskuMachineRef } = machine || {};
                let pilingMachineId;
                if (naskuMachineRef) {
                  pilingMachineId = pilingMachines[naskuMachineRef];
                  if (!pilingMachineId) {
                    throw new Error(`Missing piling machine ${naskuMachineRef}`);
                  }
                }
                let lat;
                let lng;
                let geom;
                if (epsg2105Northing && epsg2105Easting) {
                  const transform = proj4('EPSG:2105', 'EPSG:4326', [
                    epsg2105Easting,
                    epsg2105Northing,
                  ]);
                  if (transform && transform.length === 2) {
                    [lng, lat] = transform;
                    // nine decimal places, match postgis asGeoJSON
                    lng = Math.round(lng * 1000000000) / 1000000000;
                    lat = Math.round(lat * 1000000000) / 1000000000;
                    // {"type":"Point","coordinates":[-37.440314626,175.132403075]}
                    geom = {
                      type: 'Point',
                      coordinates: [lat, lng],
                    };
                  }
                }
                let isoFinishedAt;
                let isoFinishedOn;
                if (finishedAt && finishedAt > 0) {
                  isoFinishedOn = DateTime.fromMillis(finishedAt).toISODate();
                  isoFinishedAt = DateTime.fromMillis(finishedAt).toUTC().toISO();
                }
                let hammeringStatus;
                if (naskuHammeringStatus === NaskuHammeringStatuses.UNATTEMPTED) {
                  hammeringStatus = HammeringStatuses.UNATTEMPTED;
                } else if (naskuHammeringStatus === NaskuHammeringStatuses.SUCCESS) {
                  hammeringStatus = HammeringStatuses.SUCCEEDED;
                } else if (
                  naskuHammeringStatus ===
                  NaskuHammeringStatuses.INCOMPLETE_FLAGGED_FAILURE
                ) {
                  if (isoFinishedAt) {
                    hammeringStatus = HammeringStatuses.OPERATOR_COMPLETED_FLAGGED;
                  } else {
                    hammeringStatus = HammeringStatuses.INCOMPLETE_FLAGGED;
                  }
                } else if (
                  naskuHammeringStatus === NaskuHammeringStatuses.INCOMPLETE_BAD_HEIGHT
                ) {
                  if (isoFinishedAt) {
                    hammeringStatus = HammeringStatuses.OPERATOR_COMPLETED_HEIGHT;
                  } else {
                    hammeringStatus = HammeringStatuses.INCOMPLETE_HEIGHT;
                  }
                }
                if (!hammeringStatus) {
                  throw new Error(
                    `Missing hammeringStatus conversion ${naskuHammeringStatus}`
                  );
                }
                return {
                  // siteId,
                  ...(pilingMachineId && { pilingMachineId }),
                  name: pileName,
                  naskuRef: naskuRef.toString(),
                  naskuProjectRef: naskuProjectRef.toString(),
                  hammeringStatus,
                  hammeringTime,
                  epsg2105Northing,
                  epsg2105Easting,
                  lat,
                  lng,
                  geom,
                  ...(isoFinishedAt && { finishedAt: isoFinishedAt }),
                  ...(isoFinishedOn && { finishedOn: isoFinishedOn }),
                };
              });
              // const statuses = newFileRows.map((r) => r.hammeringStatus);
              // console.log(uniq(statuses));
              setFileError('');
              setFileRows(newFileRows);
              onChange(file);
            } else {
              setFileError('Document must have a "data" key with array value length > 0');
              setFileRows([]);
            }
          } else {
            setFileError('Document must be a json file');
            setFileRows([]);
          }
        } catch (err) {
          setFileError(err.message);
          setFileRows([]);
        }
      }
    },
    [pageData, enums]
  );

  const onCancel = () => {
    navigate('/');
  };

  const onFormSubmit = async () => {
    const existingPiles = get(pageData, 'pileList');
    const existingPileNames = existingPiles.map((s) => s.name);
    const rowPileNames = uniq(fileRows.map((r) => r.name));
    const createablePileNames = difference(rowPileNames, existingPileNames);
    const updateablePileNames = intersection(rowPileNames, existingPileNames);
    const createablePiles = fileRows.filter((r) => createablePileNames.includes(r.name));
    const updateablePiles = fileRows
      .filter((r) => updateablePileNames.includes(r.name))
      .map((r) => {
        const existingPile = existingPiles.find((p) => p.name === r.name);
        const whiteListExistingPile = pickValues(existingPile, pileWhiteList);
        const isEqualExistingPile = omit(whiteListExistingPile, ['id', 'tableId']);

        if (!isEqual(r, isEqualExistingPile)) {
          console.log({ isEqualExistingPile, r });
          return {
            ...whiteListExistingPile,
            ...r,
          };
        }
        return undefined;
      });

    let chunkedCreateablePilePromises = [Promise.resolve(true)];
    // console.log({ createablePiles });
    if (createablePiles.length > 0) {
      const chunkedCreateablePiles = chunk(createablePiles, 400);
      const chunkedCreateablePilesLength = chunkedCreateablePiles.length;
      chunkedCreateablePilePromises = chunkedCreateablePiles.map(
        async (chunkData, index) => {
          await pileQueue.wait(index, priority);
          console.log(`Createable Chunk: ${index + 1}/${chunkedCreateablePilesLength}`);
          try {
            return await pileBatchCreate({
              variables: { input: { piles: chunkData } },
            });
          } finally {
            pileQueue.end(index);
          }
        }
      );
    }
    await Promise.all(chunkedCreateablePilePromises);

    const compactedUpdateablePiles = compact(updateablePiles);
    // console.log({ compactedUpdateablePiles });
    let chunkedUpdateablePilePromises = [Promise.resolve(true)];
    if (compactedUpdateablePiles.length > 0) {
      const chunkedUpdateablePiles = chunk(compactedUpdateablePiles, 400);
      const chunkedUpdateablePilesLength = chunkedUpdateablePiles.length;
      chunkedUpdateablePilePromises = chunkedUpdateablePiles.map(
        async (chunkData, index) => {
          await pileQueue.wait(index, priority);
          console.log(`Updateable Chunk: ${index + 1}/${chunkedUpdateablePilesLength}`);
          try {
            return await pileBatchUpdate({
              variables: { input: { piles: chunkData } },
            });
          } finally {
            pileQueue.end(index);
          }
        }
      );
    }
    await Promise.all(chunkedUpdateablePilePromises);
    pageRefetch();
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <h1 className="h3 mb-3">Pile Import</h1>
          <p>This form is to upsert piles. It expects a JSON list from NASKU portal</p>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Confirm
                onConfirm={pileBatchTableUpdateClicked}
                title="Relink piles to tables"
                body="Confirm to relink all piles to their containing table"
                confirmText="Confirm"
              >
                <Button type="button" variant="dark">
                  Link Tables
                </Button>
              </Confirm>
            </Col>
          </Row>
        </Col>
      </Row>
      {fileError && (
        <Row>
          <Col>
            <Alert variant="danger">{fileError}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FinalForm
            onSubmit={(data) => onFormSubmit(data)}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      type="file"
                      name="file"
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                      component={InputField}
                      customOnChange={handleFileChange}
                    />
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="primary"
                              onClick={() => pageRefetch()}
                              disabled={!settingsOnline}
                            >
                              Refresh
                            </Button>
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
      {projectCode && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <p>{`Project Code: ${projectCode}`}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {fileRows.length > 0 && (
        <Row>
          <Col>
            <Tabs defaultActiveKey="rows" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="rows" title="Rows">
                <pre>{JSON.stringify(fileRows, undefined, 2)}</pre>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PileImportForm;
