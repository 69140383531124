import { useMemo, useCallback, useState } from 'react';
import {
  Col,
  Row,
  Card,
  Button,
  Alert,
  ButtonGroup,
  ButtonToolbar,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime, Duration } from 'luxon';
import { sentenceCase, constantCase } from 'change-case';

import get from 'lodash.get';

import {
  renderOverlay,
  renderOffline,
  renderError,
  renderDateString,
} from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import ReactDateTimeField from '../components/form/react_date_time_field';
import Confirm from '../components/confirm';
import { toastSuccess, toastError } from '../lib/toast_helpers';
import { handleSubmitError } from '../lib/utils';
import {
  visitorLogListPageQuery,
  visitorLogBatchProcorePoll as visitorLogBatchProcorePollMutation,
} from '../graphql/visitor_log_queries';
import { settingsReset } from '../store/settings_slice';

const VisitorLogList = () => {
  const tableStateKey = 'visitorLog';
  const dispatch = useDispatch();
  const [filterBeginAt, setFilterBeginAt] = useState(
    DateTime.now().minus({ months: 1 }).startOf('month').toISO()
  );
  const [filterEndAt, setFilterEndAt] = useState(DateTime.now().endOf('month').toISO());
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const tableColumnFilters = useSelector(
    (state) => state.settings.tableState[tableStateKey].columnFilters
  );
  const currentUser = useSelector((state) => state.auth.user);
  const [visitorLogBatchProcorePoll] = useMutation(visitorLogBatchProcorePollMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(visitorLogListPageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const dateFilteredVisitorLogs = useMemo(() => {
    if (pageData?.visitorLogList) {
      let newVisitorLogs = pageData?.visitorLogList;
      if (filterBeginAt && filterEndAt) {
        const filterBeginAtLux = DateTime.fromISO(filterBeginAt);
        const filterEndAtLux = DateTime.fromISO(filterEndAt);
        newVisitorLogs = newVisitorLogs.filter(
          (vl) =>
            DateTime.fromISO(vl.beginAt) <= filterEndAtLux &&
            DateTime.fromISO(vl.endAt) >= filterBeginAtLux
        );
      }
      return newVisitorLogs;
    }
    return [];
  }, [pageData, filterBeginAt, filterEndAt]);

  const filteredVisitorLogs = useMemo(() => {
    if (dateFilteredVisitorLogs) {
      let newVisitorLogs = dateFilteredVisitorLogs;
      if (tableColumnFilters.length > 0) {
        newVisitorLogs = newVisitorLogs.filter((vl) =>
          tableColumnFilters.every(({ id, value }) => {
            if (id === 'durationCalculationMethod') {
              return vl[id] === constantCase(value);
            }
            return vl[id] === value;
          })
        );
      }
      return newVisitorLogs;
    }
    return [];
  }, [dateFilteredVisitorLogs, tableColumnFilters]);

  const totalDuration = useMemo(() => {
    const totalMinutes = filteredVisitorLogs.reduce(
      /* eslint-disable-next-line no-return-assign, no-param-reassign */
      (accum, vl) => (accum += vl.durationMinutes),
      0
    );
    const dur = Duration.fromObject({ minutes: totalMinutes });
    const [hours, minutes] = dur.toFormat('h m').split(' ');
    return `${hours} hours and ${minutes} minutes`;
  }, [filteredVisitorLogs]);

  const tableResetClicked = useCallback(() => {
    dispatch(settingsReset(['tableState', tableStateKey]));
    setFilterBeginAt(DateTime.now().minus({ months: 1 }).startOf('month').toISO());
    setFilterEndAt(DateTime.now().endOf('month').toISO());
  }, [tableStateKey, dispatch]);

  const visitorLogBatchProcorePollClicked = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    visitorLogBatchProcorePoll()
      .then(() => {
        toastSuccess(`All visitor log updates requested`);
      })
      .catch((err) => {
        const { errorMessage } = handleSubmitError(err);
        toastError(errorMessage);
      });

  const sortIsoString = (rowA, rowB, columnId) => {
    const isoStringA = get(rowA, ['original', columnId]);
    const isoStringB = get(rowB, ['original', columnId]);
    // eslint-disable-next-line no-nested-ternary
    return isoStringA < isoStringB ? -1 : isoStringA > isoStringB ? 1 : 0;
  };

  // const getCompletedStatus = (row) => {
  //   if (row.beginAt !== row.endAt) {
  //     return 'Yes';
  //   }
  //   return 'No';
  // };

  const getBeginAt = (row) => {
    const { beginAt } = row;
    return renderDateString(beginAt);
  };

  const getEndAt = (row) => {
    const { endAt } = row;
    return renderDateString(endAt);
  };

  const getDurationCalculationMethod = (row) => {
    if (row.durationCalculationMethod) {
      return sentenceCase(row.durationCalculationMethod);
    }
    return '-';
  };

  const parentColumns = [
    {
      header: 'Date',
      accessorKey: 'reportOn',
      enableColumnFilter: false,
      sortingFn: sortIsoString,
    },
    {
      header: 'Details',
      accessorKey: 'details',
      enableColumnFilter: true,
      filterType: 'dropdown',
    },
    {
      header: 'Subject',
      accessorKey: 'subject',
      enableColumnFilter: true,
      filterType: 'dropdown',
    },
    // {
    //   header: 'Status',
    //   accessorKey: 'status',
    //   enableColumnFilter: true,
    //   filterType: 'dropdown',
    // },
    {
      header: 'Begin',
      id: 'beginAt',
      enableColumnFilter: false,
      accessorFn: (row) => getBeginAt(row),
      sortingFn: sortIsoString,
    },
    {
      header: 'End',
      id: 'endAt',
      enableColumnFilter: false,
      accessorFn: (row) => getEndAt(row),
      sortingFn: sortIsoString,
    },
    {
      header: 'Duration Minutes',
      accessorKey: 'durationMinutes',
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
      headerClassName: 'text-end',
      cellClassName: 'text-end',
    },
    {
      header: 'Calculation Method',
      accessorKey: 'durationCalculationMethod',
      accessorFn: (row) => getDurationCalculationMethod(row),
      filterType: 'dropdown',
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 mb-3">Visitor Logs</h1>
        </Col>
        <Col sm={6}>
          <Row className="justify-content-center g-0">
            <ReactDateTimeField
              input={{
                value: filterBeginAt,
                name: 'visitor-log-begin-at',
                onChange: setFilterBeginAt,
              }}
              meta={{ valid: true }}
              size="sm"
              labelWidth={0}
              inputWidth={0}
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              closeOnSelect
            />
            <Col sm="auto" className="px-2 pt-1">
              <span className="small text-muted">to...</span>
            </Col>
            <ReactDateTimeField
              input={{
                value: filterEndAt,
                name: 'visitor-log-end-at',
                onChange: (val) =>
                  setFilterEndAt(DateTime.fromISO(val).endOf('day').toISO()),
              }}
              meta={{ valid: true }}
              size="sm"
              labelWidth={0}
              inputWidth={0}
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              closeOnSelect
            />
          </Row>
        </Col>
        <Col sm="auto">
          <ButtonToolbar>
            {currentUser?.perms?.developer && (
              <ButtonGroup className="me-2">
                <Confirm
                  onConfirm={visitorLogBatchProcorePollClicked}
                  title="Update Visitor Logs"
                  body="Confirm to update visitor logs back to 2024-11-01"
                  confirmText="Confirm"
                >
                  <Button type="button" variant="danger">
                    Update Visitor Logs
                  </Button>
                </Confirm>
              </ButtonGroup>
            )}
            <ButtonGroup>
              <Button variant="primary" onClick={tableResetClicked}>
                Reset Table
              </Button>
              <Button
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh Data
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert variant="light" className="text-center">
            {`Total time for ${filteredVisitorLogs.length} filtered records:  ${totalDuration}`}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName={tableStateKey}
                parentColumns={parentColumns}
                data={dateFilteredVisitorLogs}
                doShow={false}
                doEdit={false}
                doDelete={false}
                hideActions
                hideResetTable
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default VisitorLogList;
