/* eslint-disable no-underscore-dangle */
import { useMemo, useCallback, useEffect, useState } from 'react';
import { Col, Row, Card, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { useQuery, useLazyQuery, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
  MapContainer,
  LayersControl,
  ScaleControl,
  TileLayer,
  // Marker,
  // Popup,
  // Circle,
  useMapEvents,
  // useMap,
  GeoJSON,
} from 'react-leaflet';
import { RotateCcw as RotateCcwIcon } from 'react-feather';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { centroid } from '@turf/centroid';
import arrify from 'arrify';
import objectHash from 'object-hash';
import { sentenceCase } from 'change-case';

import clone from 'lodash.clone';
import compact from 'lodash.compact';
import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';
import transform from 'lodash.transform';
import uniq from 'lodash.uniq';

import ManageModal from './site_map_show/manage_modal';
import InputField from '../components/form/input_field';
import { settingsSet } from '../store/settings_slice';
import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import { siteMapPageQuery, siteMapTableQuery } from '../graphql/site_map_queries';

const siteMapTableLabelZoomBreakpoint = 20;

// const sampleGeoJson = {
//   type: 'FeatureCollection',
//   features: [
//     {
//       type: 'Feature',
//       // geojson lat/lng format
//       geometry: { type: 'Point', coordinates: [-37.440314626, 175.132403075] },
//       // geometry: { type: 'Point', coordinates: [175.132403075, -37.440314626] },
//       properties: { hammeringStatus: 'SUCCESS' },
//     },
//   ],
// };

const addTableLabel = (map, layer, name) => {
  const label = window.L.marker(layer.getBounds().getCenter(), {
    icon: window.L.divIcon({
      className: 'label.table-label',
      html: name,
      iconSize: [65, 20],
    }),
  });
  label.addTo(map);
};

const SiteMapEvents = ({
  siteMapCenterLat,
  siteMapCenterLng,
  siteMapZoom,
  onZoomend,
  onMoveend,
}) => {
  // const map = useMap();
  const map = useMapEvents({
    moveend() {
      const currentCenter = map.getCenter();
      onMoveend(currentCenter.lat, currentCenter.lng);
    },
    zoomend() {
      const currentZoom = map.getZoom();
      if (currentZoom < siteMapTableLabelZoomBreakpoint) {
        map.eachLayer((l) => {
          if (!l.feature && l._icon && l._icon.classList.contains('label.table-label')) {
            map.removeLayer(l);
          }
        });
      }
      if (currentZoom === siteMapTableLabelZoomBreakpoint) {
        map.eachLayer((layer) => {
          const { feature } = layer;
          if (feature) {
            const { properties } = feature || {};
            const { name, tableId, pileId } = properties || {};
            if (tableId && !pileId) {
              addTableLabel(map, layer, name);
            }
          }
        });
      }
      onZoomend(currentZoom);
    },
  });
  useEffect(() => {
    map.setView(new window.L.LatLng(siteMapCenterLat, siteMapCenterLng), siteMapZoom);
  }, [map, siteMapCenterLat, siteMapCenterLng, siteMapZoom]);
  return null;
};

const SiteMap = () => {
  const dispatch = useDispatch();
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const siteMapCenterLat = useSelector((state) => state.settings.siteMapCenterLat);
  const siteMapCenterLng = useSelector((state) => state.settings.siteMapCenterLng);
  const siteMapDefaultCenterLat = useSelector(
    (state) => state.settings.siteMapDefaultCenterLat
  );
  const siteMapDefaultCenterLng = useSelector(
    (state) => state.settings.siteMapDefaultCenterLng
  );
  const siteMapZoom = useSelector((state) => state.settings.siteMapZoom);
  const siteMapDefaultZoom = useSelector((state) => state.settings.siteMapDefaultZoom);
  const siteMapTypeaheadTableId = useSelector(
    (state) => state.settings.siteMapTypeaheadTableId
  );
  const siteMapFilterBuildStatus = useSelector(
    (state) => state.settings.siteMapFilterBuildStatus
  );
  const siteMapFilterInverterId = useSelector(
    (state) => state.settings.siteMapFilterInverterId
  );
  const siteMapFilterCombinerBoxName = useSelector(
    (state) => state.settings.siteMapFilterCombinerBoxName
  );
  const [showManageModal, setShowManageModal] = useState(false);
  const [currentTable, setCurrentTable] = useState({});
  const [tableById] = useLazyQuery(siteMapTableQuery);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(siteMapPageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const enums = useMemo(() => get(pageData, 'enums.enums', {}), [pageData]);
  const TableBuildStatuses = useMemo(() => get(enums, 'TableBuildStatuses', {}), [enums]);

  const tablesFeatureCollection = useMemo(() => {
    if (pageData?.tableGeoJsonList) {
      return pageData.tableGeoJsonList.geojson;
    }
    return null;
  }, [pageData]);

  const filteredTablesFeatureCollection = useMemo(() => {
    if (tablesFeatureCollection) {
      let newFeatures = tablesFeatureCollection.features;
      if (siteMapFilterInverterId) {
        newFeatures = newFeatures.filter(
          (feature) => get(feature, 'properties.inverterId') === siteMapFilterInverterId
        );
      }
      if (siteMapFilterBuildStatus) {
        newFeatures = newFeatures.filter(
          (feature) => get(feature, 'properties.buildStatus') === siteMapFilterBuildStatus
        );
      }
      if (siteMapFilterCombinerBoxName) {
        newFeatures = newFeatures.filter((feature) =>
          defaultTo(get(feature, 'properties.name'), '').includes(
            siteMapFilterCombinerBoxName
          )
        );
      }
      return newFeatures;
    }
    return null;
  }, [
    tablesFeatureCollection,
    siteMapFilterBuildStatus,
    siteMapFilterInverterId,
    siteMapFilterCombinerBoxName,
  ]);

  const filteredTablesFeatureCollectionObjectHash = useMemo(() => {
    if (filteredTablesFeatureCollection) {
      return objectHash(filteredTablesFeatureCollection);
    }
    return null;
  }, [filteredTablesFeatureCollection]);

  const pilesFeatureCollection = useMemo(() => {
    if (pageData?.pileGeoJsonList) {
      return pageData.pileGeoJsonList.geojson;
    }
    return null;
  }, [pageData]);

  const filteredPilesFeatureCollection = useMemo(() => {
    if (pilesFeatureCollection) {
      let newFeatures = pilesFeatureCollection.features;
      if (siteMapFilterInverterId) {
        newFeatures = newFeatures.filter(
          (feature) => get(feature, 'properties.inverterId') === siteMapFilterInverterId
        );
      }
      if (siteMapFilterBuildStatus) {
        newFeatures = newFeatures.filter(
          (feature) =>
            get(feature, 'properties.tableBuildStatus') === siteMapFilterBuildStatus
        );
      }
      if (siteMapFilterCombinerBoxName) {
        newFeatures = newFeatures.filter((feature) =>
          defaultTo(get(feature, 'properties.tableName'), '').includes(
            siteMapFilterCombinerBoxName
          )
        );
      }
      return newFeatures;
    }
    return null;
  }, [
    pilesFeatureCollection,
    siteMapFilterBuildStatus,
    siteMapFilterInverterId,
    siteMapFilterCombinerBoxName,
  ]);

  const filteredPilesFeatureCollectionObjectHash = useMemo(() => {
    if (filteredPilesFeatureCollection) {
      return objectHash(filteredPilesFeatureCollection);
    }
    return null;
  }, [filteredPilesFeatureCollection]);

  const typeaheadTables = useMemo(() => {
    if (pageData?.tableList) {
      const tableList = clone(pageData.tableList);
      return tableList.sort((a, b) =>
        a.name.localeCompare(b.name, 'en', { numeric: true })
      );
    }
    return [];
  }, [pageData]);

  const filterBuildStatuses = useMemo(() => {
    if (TableBuildStatuses) {
      const selection = transform(
        TableBuildStatuses,
        (result, value, key) => {
          result.push({ id: key, name: sentenceCase(value) });
          return result;
        },
        []
      );
      return selection;
    }
    return [];
  }, [TableBuildStatuses]);

  const filterInverters = useMemo(() => {
    if (pageData?.inverterList) {
      return pageData.inverterList;
    }
    return [];
  }, [pageData]);

  const filterCombinerBoxes = useMemo(() => {
    if (tablesFeatureCollection?.features) {
      const tableNames = tablesFeatureCollection.features.map(
        (feature) => feature.properties.name
      );
      const uniqTableNames = uniq(tableNames.map((n) => n.split('-')[1])).sort((a, b) =>
        a.localeCompare(b, 'en', { numeric: true })
      );
      return uniqTableNames.map((n) => ({ id: n, name: n }));
    }
    return [];
  }, [tablesFeatureCollection]);

  const tableMarkerOptions = useMemo(
    () => ({
      weight: 1,
      opacity: 1,
      fillOpacity: 0.7,
      color: 'black',
      fillColor: 'black',
    }),
    []
  );

  const getTableMarkerOptions = useCallback(
    (feature) => {
      const {
        WAITING_COORDINATES,
        READY_TO_START,
        PILING_IN_PROGRESS,
        PILING_WORKS_COMPLETE,
        CONTRACTOR_PILING_CHECKLIST_IN_PROGRESS,
        CONTRACTOR_PILING_CHECKLIST_NON_CONFORMING,
        CONTRACTOR_PILING_CHECKLIST_COMPLETE,
        INSPECTOR_PILING_CHECKLIST_IN_PROGRESS,
        INSPECTOR_PILING_CHECKLIST_NON_CONFORMING,
        INSPECTOR_PILING_CHECKLIST_COMPLETE,
        COMPLETE,
      } = TableBuildStatuses;
      const { properties } = feature;
      const { buildStatus, tableId } = properties || {};
      const isTableSelected = tableId === siteMapTypeaheadTableId;
      let color;
      let fillOpacity;
      if (buildStatus === WAITING_COORDINATES) {
        color = 'red';
        fillOpacity = 0.5;
      } else if (buildStatus === READY_TO_START) {
        color = 'white';
        fillOpacity = 0.5;
      } else if (buildStatus === PILING_IN_PROGRESS) {
        color = 'white';
        fillOpacity = 1;
      } else if (buildStatus === PILING_WORKS_COMPLETE) {
        color = 'olive';
      } else if (
        buildStatus === CONTRACTOR_PILING_CHECKLIST_NON_CONFORMING ||
        buildStatus === INSPECTOR_PILING_CHECKLIST_NON_CONFORMING
      ) {
        color = 'red';
        fillOpacity = 1;
      } else if (buildStatus === CONTRACTOR_PILING_CHECKLIST_IN_PROGRESS) {
        color = 'green';
        fillOpacity = 0.25;
      } else if (buildStatus === CONTRACTOR_PILING_CHECKLIST_COMPLETE) {
        color = 'green';
        fillOpacity = 0.5;
      } else if (buildStatus === INSPECTOR_PILING_CHECKLIST_IN_PROGRESS) {
        color = 'green';
        fillOpacity = 0.75;
      } else if (buildStatus === INSPECTOR_PILING_CHECKLIST_COMPLETE) {
        color = 'green';
        fillOpacity = 1;
      } else if (buildStatus === COMPLETE) {
        color = 'indigo';
        fillOpacity = 1;
      }
      return {
        ...tableMarkerOptions,
        ...(color && { color }),
        ...(color && { fillColor: color }),
        ...(fillOpacity && { fillOpacity }),
        ...(isTableSelected && { weight: 5 }),
      };
    },
    [TableBuildStatuses, tableMarkerOptions, siteMapTypeaheadTableId]
  );

  const pileMarkerOptions = useMemo(
    () => ({
      radius: 0.3,
      weight: 1,
      opacity: 1,
      fillOpacity: 0.7,
      color: 'white',
      fillColor: 'white',
    }),
    []
  );

  const getPileMarkerOptions = useCallback(
    (feature) => {
      const {
        SucceededHammeringStatus,
        ContractorCompletedHammeringStatuses,
        CompletedHammeringStatuses,
        IncompleteHammeringStatuses,
      } = enums;
      const ContractorCompletedHammeringStatusValues = Object.values(
        ContractorCompletedHammeringStatuses
      );
      const CompletedHammeringStatusValues = Object.values(CompletedHammeringStatuses);
      const IncompleteHammeringStatusValues = Object.values(IncompleteHammeringStatuses);
      const { properties } = feature;
      const { hammeringStatus } = properties || {};
      if (hammeringStatus === SucceededHammeringStatus) {
        return { ...pileMarkerOptions, color: 'green', fillColor: 'green' };
      }
      if (ContractorCompletedHammeringStatusValues.includes(hammeringStatus)) {
        return { ...pileMarkerOptions, color: 'lime', fillColor: 'lime' };
      }
      if (CompletedHammeringStatusValues.includes(hammeringStatus)) {
        return { ...pileMarkerOptions, color: 'olive', fillColor: 'olive' };
      }
      if (IncompleteHammeringStatusValues.includes(hammeringStatus)) {
        return { ...pileMarkerOptions, color: 'red', fillColor: 'red' };
      }
      return pileMarkerOptions;
    },
    [enums, pileMarkerOptions]
  );

  const renderPointToLayerPile = (feature, latLng) =>
    window.L.circle(latLng, getPileMarkerOptions(feature));

  const onEachFeaturePile = (feature, layer) => {
    const { properties } = feature;
    const { name, hammeringStatus, finishedOn } = properties || {};
    const tooltip = compact([name, sentenceCase(hammeringStatus), finishedOn]).join(
      ' - '
    );
    layer.on('add', (e) => {
      const addLayer = e.target;
      addLayer.bindTooltip(tooltip, { direction: 'center' });
    });
  };

  const handleManageModalCancel = () => {
    setShowManageModal(false);
    setCurrentTable({});
  };

  const handleManageModalComplete = (data) => {
    setShowManageModal(false);
    setCurrentTable({});
    // onFormSubmit({
    //   id: lastScanned.id,
    //   receiptNotes: data.receiptNotes,
    //   auditNotes: makeAuditNote('Delivery notes updated'),
    // });
    console.log('data');
    console.log(data);
    return true;
  };

  const handleTableClicked = async (tableId) => {
    let newCurrentTable = {};
    if (tableId) {
      const currentTableResp = await tableById({
        variables: {
          tableId,
        },
        fetchPolicy: 'no-cache',
      });
      newCurrentTable = get(currentTableResp, 'data.table');
      if (newCurrentTable?.id) {
        setCurrentTable(newCurrentTable);
      }
      setShowManageModal(true);
    }
  };

  const onEachFeatureTable = (feature, layer) => {
    const { properties } = feature;
    const { tableId, name, buildStatus, tableType } = properties || {};
    const tooltip = `${name} - ${sentenceCase(tableType)} - ${sentenceCase(buildStatus)}`;
    let dblClickTimer = null;
    layer.on('add', (e) => {
      const addLayer = e.target;
      addLayer.bindTooltip(tooltip, { direction: 'top' });
      const currentZoom = addLayer._map._zoom;
      if (currentZoom >= siteMapTableLabelZoomBreakpoint) {
        addTableLabel(addLayer._map, addLayer, name);
      }
    });
    layer.on('click', () => {
      if (dblClickTimer !== null) {
        return;
      }
      dblClickTimer = setTimeout(() => {
        handleTableClicked(tableId);
        dblClickTimer = null;
      }, 200);
    });
    layer.on('dblclick', () => {
      clearTimeout(dblClickTimer);
      dblClickTimer = null;
    });
  };

  const styleTable = (feature) => getTableMarkerOptions(feature);

  const coordsToLatLng = (coords) => new window.L.LatLng(coords[0], coords[1]);

  const onTypeaheadChange = (data) => {
    const table = get(data, [0], {});
    let newSiteMapCenterLat = siteMapDefaultCenterLat;
    let newSiteMapCenterLng = siteMapDefaultCenterLng;
    let newSiteMapZoom = siteMapDefaultZoom;
    if (table.geom) {
      const tableCentroid = centroid(table.geom);
      const [lat, lng] = get(tableCentroid, 'geometry.coordinates', []);
      if (lat && lng) {
        newSiteMapCenterLat = lat;
        newSiteMapCenterLng = lng;
        newSiteMapZoom = siteMapTableLabelZoomBreakpoint;
      }
    }
    dispatch(
      settingsSet({
        siteMapTypeaheadTableId: table.id || '',
        siteMapCenterLat: newSiteMapCenterLat,
        siteMapCenterLng: newSiteMapCenterLng,
        siteMapZoom: newSiteMapZoom,
      })
    );
  };

  const onBuildStatusChange = async (e) => {
    const buildStatus = defaultTo(e.target.value, '');
    dispatch(
      settingsSet({
        siteMapFilterBuildStatus: buildStatus,
      })
    );
  };

  const onInverterIdChange = async (e) => {
    const inverterId = defaultTo(parseInt(e.target.value, 10), '');
    dispatch(
      settingsSet({
        siteMapFilterInverterId: inverterId,
      })
    );
  };

  const onCombinerBoxChange = (e) => {
    const combinerBoxName = defaultTo(e.target.value, '');
    dispatch(
      settingsSet({
        siteMapFilterCombinerBoxName: combinerBoxName,
      })
    );
  };

  const onResetFilterClicked = () => {
    dispatch(
      settingsSet({
        siteMapTypeaheadTableId: '',
        siteMapFilterBuildStatus: '',
        siteMapFilterInverterId: '',
        siteMapFilterCombinerBoxName: '',
        siteMapCenterLat: siteMapDefaultCenterLat,
        siteMapCenterLng: siteMapDefaultCenterLng,
        siteMapZoom: siteMapDefaultZoom,
      })
    );
  };

  const onZoomend = (newSiteMapZoom) => {
    dispatch(
      settingsSet({
        siteMapZoom: newSiteMapZoom,
      })
    );
  };

  const onMoveend = (newSiteMapCenterLat, newSiteMapCenterLng) => {
    dispatch(
      settingsSet({
        siteMapCenterLat: newSiteMapCenterLat,
        siteMapCenterLng: newSiteMapCenterLng,
      })
    );
  };

  const renderContent = () => (
    <>
      <ManageModal
        show={showManageModal}
        onCancel={handleManageModalCancel}
        onComplete={handleManageModalComplete}
        settingsOnline={settingsOnline}
        table={currentTable}
        tableBuildStatuses={TableBuildStatuses}
      />
      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 mb-3">Site Map</h1>
        </Col>
        <Col sm={8}>
          <Row className="justify-content-center g-0">
            <InputField
              labelWidth={0}
              inputWidth={3}
              input={{
                name: 'site-map-table',
              }}
              groupClassName="me-2"
              innerContent={
                <Typeahead
                  id="site-map-table-typeahead-single"
                  labelKey="name"
                  onChange={onTypeaheadChange}
                  options={typeaheadTables}
                  placeholder="search table..."
                  selected={arrify(
                    typeaheadTables.find((t) => t.id === siteMapTypeaheadTableId)
                  )}
                />
              }
            />
            <InputField
              size="md"
              groupClassName="me-2"
              labelWidth={0}
              inputWidth={3}
              input={{
                name: 'site-map-build-status',
                value: siteMapFilterBuildStatus,
                onChange: onBuildStatusChange,
              }}
              meta={{}}
              type="text"
              asElement="select"
              defaultSelectOptionName="build status..."
              selectOptions={filterBuildStatuses}
            />
            <InputField
              size="md"
              groupClassName="me-2"
              labelWidth={0}
              inputWidth={2}
              input={{
                name: 'site-map-inverter',
                value: siteMapFilterInverterId,
                onChange: onInverterIdChange,
              }}
              meta={{}}
              type="text"
              asElement="select"
              defaultSelectOptionName="inverter..."
              selectOptions={filterInverters}
            />
            <InputField
              size="md"
              groupClassName="me-2"
              labelWidth={0}
              inputWidth={2}
              input={{
                name: 'site-map-combiner-box',
                value: siteMapFilterCombinerBoxName,
                onChange: onCombinerBoxChange,
              }}
              meta={{}}
              type="text"
              asElement="select"
              defaultSelectOptionName="combiner box..."
              selectOptions={filterCombinerBoxes}
            />
            <Col sm={1}>
              <Button size="md" variant="primary" onClick={onResetFilterClicked}>
                <RotateCcwIcon size={16} />
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <ButtonToolbar className="justify-content-end">
            <ButtonGroup>
              <Button
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <MapContainer
                style={{ height: 'calc(100vh - 215px)' }}
                center={{ lat: siteMapCenterLat, lng: siteMapCenterLng }}
                zoom={siteMapZoom}
                maxZoom={23}
                scrollWheelZoom={false}
              >
                <SiteMapEvents
                  onZoomend={onZoomend}
                  onMoveend={onMoveend}
                  siteMapCenterLat={siteMapCenterLat}
                  siteMapCenterLng={siteMapCenterLng}
                  siteMapZoom={siteMapZoom}
                />
                <ScaleControl position="topleft" imperial={false} />
                <LayersControl>
                  <LayersControl.BaseLayer name="Open Street Maps">
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Hybrid">
                    <ReactLeafletGoogleLayer
                      apiKey="AIzaSyCzoDa5VVrDL4XQPJ1QP35HAdeVZ0OGye8"
                      type="hybrid"
                      maxZoom={23}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satellite">
                    <ReactLeafletGoogleLayer
                      apiKey="AIzaSyCzoDa5VVrDL4XQPJ1QP35HAdeVZ0OGye8"
                      type="satellite"
                      maxZoom={23}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer checked name="Street">
                    <ReactLeafletGoogleLayer
                      apiKey="AIzaSyCzoDa5VVrDL4XQPJ1QP35HAdeVZ0OGye8"
                      type="roadmap"
                      maxZoom={23}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.Overlay name="piles">
                    <GeoJSON
                      key={filteredPilesFeatureCollectionObjectHash}
                      data={filteredPilesFeatureCollection}
                      // key={objectHash(sampleGeoJson)}
                      // data={sampleGeoJson}
                      pointToLayer={renderPointToLayerPile}
                      onEachFeature={onEachFeaturePile}
                      coordsToLatLng={coordsToLatLng}
                    />
                  </LayersControl.Overlay>
                  <LayersControl.Overlay checked name="tables">
                    <GeoJSON
                      key={filteredTablesFeatureCollectionObjectHash}
                      data={filteredTablesFeatureCollection}
                      onEachFeature={onEachFeatureTable}
                      coordsToLatLng={coordsToLatLng}
                      style={styleTable}
                      // TODO add the filter function and filter by inverter
                      // will need to add to properties
                    />
                  </LayersControl.Overlay>
                </LayersControl>
              </MapContainer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default SiteMap;
