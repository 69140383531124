import { useMemo, useCallback } from 'react';
import { Col, Row, Card, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ModalImage from 'react-modal-image';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { validate as uuidValidate } from 'uuid';

import get from 'lodash.get';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import Confirm from '../components/confirm';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import {
  consignmentImageListPageQuery,
  consignmentImageDelete as consignmentImageDeleteMutation,
  consignmentImageBatchSignedUrlUpdate as consignmentImageBatchSignedUrlUpdateMutation,
} from '../graphql/consignment_image_queries';
import { handleSubmitError } from '../lib/utils';
import { settingsSet, settingsReset } from '../store/settings_slice';
import * as updateFunctions from '../update_functions';

const ConsignmentImageList = () => {
  const tableStateKey = 'consignmentImage';
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const [consignmentImageDelete] = useMutation(consignmentImageDeleteMutation);
  const [consignmentImageBatchSignedUrlUpdate] = useMutation(
    consignmentImageBatchSignedUrlUpdateMutation
  );

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(consignmentImageListPageQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ consignmentImageList }) => {
      // write an individual query for every consignmentImage in the list
      updateFunctions.consignmentImageList(pageClient, consignmentImageList);
    },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const tableResetClicked = useCallback(() => {
    dispatch(settingsReset(['tableState', tableStateKey]));
  }, [tableStateKey, dispatch]);

  const consignmentImageBatchSignedUrlUpdateClicked = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    consignmentImageBatchSignedUrlUpdate()
      .then(() => {
        toastSuccess(`All signed url updates requested`);
      })
      .catch((err) => {
        const { errorMessage } = handleSubmitError(err);
        toastError(errorMessage);
      });

  const consignmentImageDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const consignmentImageId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: consignmentImageId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ConsignmentImageType',
        recordId: consignmentImageId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.consignmentImageDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'consignmentImageDelete',
      mutationData
    );
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await consignmentImageDelete(mutationData);
        toastSuccess('Consignment Image delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Consignment Image delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    } else {
      consignmentImageDelete(mutationData);
      toastWarning(`Consignment Image delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const getConsignmentName = (row) => {
    const { consignment } = row;
    const { name } = consignment || {};
    return name || '-';
  };

  const getConsignmentItemName = (row) => {
    const { consignmentItem } = row;
    const { consignmentItemReference } = consignmentItem || {};
    return consignmentItemReference || '-';
  };

  const getReceiptName = (row) => {
    const { receipt } = row;
    const { name } = receipt || {};
    return name || '-';
  };

  const renderSignedUrl = ({ row }) => {
    const signedUrlOriginal = get(row, ['original', 'signedUrlOriginal']);
    const fileFilename = get(row, ['original', 'fileFilename'], 'Original');
    if (signedUrlOriginal) {
      return (
        <ModalImage
          small="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/WPCA_-_Picture_icon_gray_infobox.svg/25px-WPCA_-_Picture_icon_gray_infobox.svg.png"
          large={signedUrlOriginal}
          alt={fileFilename}
          showRotate
        />
      );
    }
    return undefined;
  };

  const parentColumns = [
    {
      header: 'Consignment',
      id: 'consignment',
      accessorFn: (row) => getConsignmentName(row),
      filterType: 'dropdown',
    },
    {
      header: 'Consignment Item',
      id: 'consignmentItem',
      accessorFn: (row) => getConsignmentItemName(row),
      filterType: 'dropdown',
    },
    {
      header: 'Receipt',
      id: 'receipt',
      accessorFn: (row) => getReceiptName(row),
      filterType: 'dropdown',
    },
    {
      header: 'Filename',
      accessorKey: 'fileFilename',
      enableColumnFilter: false,
    },
    {
      header: 'Filetype',
      accessorKey: 'fileMimetype',
      enableColumnFilter: false,
    },
    {
      header: 'View Image',
      accessorKey: 'signedUrlOriginal',
      enableColumnFilter: false,
      cell: renderSignedUrl,
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 mb-3">Consignment Images</h1>
        </Col>
        <Col sm="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              {currentUser?.perms?.developer && (
                <Confirm
                  onConfirm={consignmentImageBatchSignedUrlUpdateClicked}
                  title="Update Sign URLs"
                  body="Confirm to update all expired signed urls"
                  confirmText="Confirm"
                >
                  <Button type="button" variant="danger">
                    Update Signed URLs
                  </Button>
                </Confirm>
              )}
              <LinkContainer to="/consignment_images/new">
                <Button variant="primary">New Consignment Image</Button>
              </LinkContainer>
            </ButtonGroup>
            <ButtonGroup>
              <Button variant="primary" onClick={tableResetClicked}>
                Reset Table
              </Button>
              <Button
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh Data
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName={tableStateKey}
                parentColumns={parentColumns}
                data={pageData.consignmentImageList}
                doShow={false}
                doDelete={currentUser?.perms?.administrator}
                handleDelete={consignmentImageDeleteClicked}
                hideResetTable
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ConsignmentImageList;
